* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
}

#root{
    height: 100%;
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-green {
    background-image: url(../../../public/assets/images/green.avif);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
}

.bg-purple {
    background-image: url(../../../public/assets/images/purple.avif);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
}

.bg-blue {
    background-image: url(../../../public/assets/images/blue.avif);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
}

.custom-select-container > .custom-select__control {
    padding: 6px 12px;
    border-radius: 18px;
}

.react-datepicker-wrapper {
    width: 100%;
    border: hsl(0, 0%, 80%);
}

/* fonts default */
.fs-8rem {
	font-size: 0.5rem;
}

.fs-9rem {
	font-size: 0.5625rem;
}

.fs-10rem {
	font-size: 0.625rem;
}

.fs-11rem {
	font-size: 0.688rem;
}

.fs-12rem {
	font-size: 0.75rem;
}

.fs-13rem {
	font-size: 0.8125rem;
}

.fs-14rem {
	font-size: 0.875rem;
}

.fs-16rem {
	font-size: 1rem;
}

.fs-20rem{
	font-size: 1.2rem;
}

.fs-22rem {
	font-size: 1.375rem;
}

.fs-24rem {
	font-size: 1.5rem;
}

.fs-26rem {
	font-size: 1.625rem;
}

.fs-28rem{
	font-size:28px;
}

.fs-30rem{
	font-size:1.875rem;
}

.fs-34rem {
	font-size: 2.125rem;
}

.fs-36rem {
	font-size: 2.25rem;
}

.fs-48rem {
	font-size: 3rem;
}

.fs-50rem{
	font-size: 4rem;
}

.profile {
    font-size: 0 !important;
    border-radius: 100px !important;
    width: 50px;
    height: 50px;
}

.custom-select-container, .custom-select__control {
    cursor: pointer !important;
}