
.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formbold-form-wrapper {
  margin: 0 auto;
  width: 100%;
  background: white;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  padding: 20px;
}

.formbold-form-label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  margin-bottom: 12px;
}

.formbold-form-input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: white;
  font-size: 16px;
  color: #6b7280;
  outline: none;
  resize: none;
}

.formbold-form-input:focus {
  border-color: #6a64f1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-file-input input {
  /* height: 0px; */
    opacity: 1;
    /* width: 100%; */
    position: absolute;
    left: 12rem;
    bottom: 0;
    color: #198755;
    font-weight: 500;
}

.formbold-file-input label {
  position: relative;
  border: 1px dashed #e0e0e0;
  border-radius: 20px;
  /* min-height: 200px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
}

.formbold-drop-file {
  display: block;
  font-weight: 600;
  color: #07074d;
  font-size: 20px;
  margin-bottom: 8px;
}

.formbold-or {
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  display: block;
  margin-bottom: 8px;
}

.formbold-browse {
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  display: inline-block;
  padding: 8px 28px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.formbold-file-list {
  border-radius: 6px;
  background: #f5f7fb;
  padding: 16px 32px;
}
input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  opacity:0;
}
